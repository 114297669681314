import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { useAuth } from '../context/AuthContext';

const Header = () => {
  const { user, userData, handleSignOut, handleSignUp, handleSignIn } = useAuth();
  const [showAuth, setShowAuth] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSignUp, setIsSignUp] = useState(false);
  const [error, setError] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [favoriteWord, setFavoriteWord] = useState('');
  const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      if (isSignUp) {
        if (!firstName || !lastName || !email || !password || !favoriteWord) {
          throw new Error('All fields are required');
        }
        await handleSignUp(email, password, firstName, lastName, favoriteWord);
      } else {
        await handleSignIn(email, password);
      }
      resetForm();
      setShowAuth(false);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setEmail('');
    setPassword('');
    setFirstName('');
    setLastName('');
    setFavoriteWord('');
    setError('');
  };

  const handleLogoutClick = async () => {
    setLoading(true);
    try {
      await handleSignOut();
      setShowLogoutConfirm(false);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const closeAuth = () => {
    setShowAuth(false);
    resetForm();
  };

  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      closeAuth();
    }
  };

  return (
    <header className="fixed top-0 w-full z-50 bg-white">
      <div className="flex justify-between items-center px-6 py-2">
        <div className="flex items-center">
          {/* Left side empty now */}
        </div>

        <div className="flex items-center gap-4">
          {user && (
            <>
              <span className="text-sm text-black/60">
                {userData?.firstName || user.email}
              </span>
              <button 
                onClick={() => setShowLogoutConfirm(true)}
                className="w-8 h-8 flex items-center justify-center hover:opacity-50 transition-opacity"
              >
                <span className="text-2xl font-light">−</span>
              </button>
            </>
          )}
          {!user && (
            <button 
              onClick={() => setShowAuth(true)}
              className="w-8 h-8 flex items-center justify-center hover:opacity-50 transition-opacity"
            >
              <span className="text-2xl font-light">+</span>
            </button>
          )}
        </div>

        <AnimatePresence>
          {showLogoutConfirm && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
              className="fixed inset-0 bg-black z-50 flex items-center justify-center px-4"
            >
              <motion.div 
                initial={{ scale: 0.95 }}
                animate={{ scale: 1 }}
                exit={{ scale: 0.95 }}
                className="bg-black w-full max-w-sm p-8 text-white border border-white/10"
              >
                <div className="flex justify-between items-center mb-8">
                  <h2 className="text-xl font-light">are you sure you want to leave?</h2>
                  <button 
                    onClick={() => setShowLogoutConfirm(false)}
                    className="text-white/40 hover:text-white transition-colors text-2xl leading-none"
                  >
                    <span className="text-2xl font-light">×</span>
                  </button>
                </div>

                <div className="flex gap-2">
                  <button
                    onClick={() => setShowLogoutConfirm(false)}
                    className="flex-1 p-2 text-sm border border-white/20 text-white/60 hover:text-white transition-colors"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleLogoutClick}
                    disabled={loading}
                    className="flex-1 p-2 text-sm bg-white text-black hover:bg-white/90 transition-colors disabled:opacity-50"
                  >
                    {loading ? '...' : 'Log out'}
                  </button>
                </div>
              </motion.div>
            </motion.div>
          )}

          {showAuth && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
              className="fixed inset-0 bg-black z-50 flex items-center justify-center px-4"
              onClick={handleBackdropClick}
            >
              <motion.div 
                initial={{ scale: 0.95 }}
                animate={{ scale: 1 }}
                exit={{ scale: 0.95 }}
                className="relative bg-black w-full max-w-sm p-8 text-white border border-white/10"
              >
                <button 
                  onClick={() => closeAuth()}
                  className="absolute top-4 right-4 text-white/40 hover:text-white transition-colors text-2xl leading-none"
                >
                  ×
                </button>
                
                <h2 className="text-xl font-light mb-8">we are leaving this _______ for now. talk to you soon.</h2>

                <div className="flex gap-2 mb-8">
                  <button
                    onClick={() => setIsSignUp(false)}
                    className={`flex-1 p-2 text-sm transition-colors ${!isSignUp ? 'bg-white text-black' : 'border border-white/20 text-white/60 hover:text-white'}`}
                  >
                    Log-in
                  </button>
                  <button
                    onClick={() => setIsSignUp(true)}
                    className={`flex-1 p-2 text-sm transition-colors ${isSignUp ? 'bg-white text-black' : 'border border-white/20 text-white/60 hover:text-white'}`}
                  >
                    Sign-up
                  </button>
                </div>

                {error && (
                  <div className="mb-4 text-red-400 text-sm">{error}</div>
                )}

                <form onSubmit={handleSubmit} className="space-y-4">
                  {isSignUp && (
                    <>
                      <input
                        type="text"
                        placeholder="First name"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        className="w-full p-2 bg-transparent border border-white/20 focus:border-white/40 outline-none transition-colors text-white placeholder-white/40"
                      />
                      <input
                        type="text"
                        placeholder="Last name"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        className="w-full p-2 bg-transparent border border-white/20 focus:border-white/40 outline-none transition-colors text-white placeholder-white/40"
                      />
                    </>
                  )}
                  <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full p-2 bg-transparent border border-white/20 focus:border-white/40 outline-none transition-colors text-white placeholder-white/40"
                  />
                  <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="w-full p-2 bg-transparent border border-white/20 focus:border-white/40 outline-none transition-colors text-white placeholder-white/40"
                  />
                  {isSignUp && (
                    <input
                      type="text"
                      placeholder="Favorite word"
                      value={favoriteWord}
                      onChange={(e) => setFavoriteWord(e.target.value)}
                      className="w-full p-2 bg-transparent border border-white/20 focus:border-white/40 outline-none transition-colors text-white placeholder-white/40"
                    />
                  )}
                  <button
                    type="submit"
                    disabled={loading || !email || !password || (isSignUp && (!firstName || !lastName || !favoriteWord))}
                    className="w-full p-2 bg-white text-black hover:bg-white/90 transition-colors disabled:opacity-50"
                  >
                    {loading ? '...' : isSignUp ? 'Register' : 'Login'}
                  </button>
                </form>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </header>
  );
};

export default Header;

