import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import sanityClient from '../components/sanityClient';

const ProjectGrid = () => {
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [hoveredId, setHoveredId] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "project"] | order(dateCreated desc){
          _id,
          dateCreated,
          title,
          subtitle,
          projectType,
          externalUrl,
          widgets[]{
            type,
            url,
            "fileUrl": file.asset->url,
            "thumbnailUrl": thumbnail.asset->url,
            displayWhen
          }
        }`
      )
      .then((data) => {
        console.log('Fetched projects:', data);
        setProjects(data);
      })
      .catch(console.error);
  }, []);

  const handleProjectClick = (project) => {
    if (project.projectType === 'link') {
      window.open(project.externalUrl, '_blank');
    } else {
      setSelectedProject(selectedProject?._id === project._id ? null : project);
    }
  };

  const getVideoEmbedUrl = (url) => {
    if (url?.includes('youtube.com') || url?.includes('youtu.be')) {
      const videoId = url.includes('youtu.be') 
        ? url.split('/').pop()
        : url.split('v=')[1]?.split('&')[0];
      return `https://www.youtube-nocookie.com/embed/${videoId}?autoplay=1&loop=1&playlist=${videoId}&controls=0&modestbranding=1`;
    }
    if (url?.includes('vimeo')) {
      const vimeoId = url.split('/').pop();
      return `https://player.vimeo.com/video/${vimeoId}?autoplay=1&loop=1&background=1`;
    }
    return url;
  };

  const renderWidget = (widget, isExpanded) => {
    if (widget.displayWhen === 'expanded' && !isExpanded) return null;
    
    console.log('Rendering widget:', widget);
    
    switch (widget.type) {
      case 'pdf':
        return (
          <div 
            onClick={(e) => {
              e.stopPropagation();
              window.open(widget.fileUrl, '_blank');
            }}
            className="block w-full relative group cursor-pointer"
          >
            {widget.thumbnailUrl ? (
              <>
                <div className="aspect-[3/4] overflow-hidden">
                  <img 
                    src={widget.thumbnailUrl}
                    alt="PDF thumbnail"
                    className="w-full h-full object-contain"
                  />
                </div>
                <div className="absolute inset-0 bg-black/0 group-hover:bg-black/5 transition-colors flex items-center justify-center">
                  <span className="bg-white/90 px-3 py-1.5 rounded-sm text-sm text-black/70 opacity-0 group-hover:opacity-100 transition-opacity">
                    Open PDF
                  </span>
                </div>
              </>
            ) : (
              <div className="aspect-[3/4] w-full flex items-center justify-center bg-black/5">
                <span className="text-black/40">PDF Document</span>
              </div>
            )}
          </div>
        );

      case 'video':
        return (
          <div className="aspect-video bg-black">
            <iframe
              src={getVideoEmbedUrl(widget.url)}
              className="w-full h-full"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              loading="lazy"
            />
          </div>
        );
      
      default: // image
        return (
          <img 
            src={widget.url}
            alt=""
            className="w-full"
            loading="lazy"
          />
        );
    }
  };

  return (
    <div className="min-h-screen w-full bg-white px-6 pt-24 pb-16">
      <div className="max-w-6xl mx-auto space-y-4">
        {projects.map((project) => (
          <motion.div
            key={project._id}
            layout
            onMouseEnter={() => setHoveredId(project._id)}
            onMouseLeave={() => setHoveredId(null)}
            className={`transition-all duration-200 border border-transparent hover:border-black/10 p-4 rounded-sm ${
              selectedProject?._id === project._id ? 'border-black/10' : ''
            }`}
          >
            <motion.div
              className="cursor-pointer"
              onClick={() => handleProjectClick(project)}
              layout
            >
              <div className="flex flex-col sm:flex-row items-baseline justify-between gap-2">
                <span className={`text-sm transition-colors duration-200 ${
                  hoveredId === project._id ? 'text-black/60' : 'text-black/40'
                }`}>
                  {new Date(project.dateCreated).toLocaleDateString('en-US', {
                    year: '2-digit',
                    month: '2-digit',
                    day: '2-digit'
                  })}
                </span>
                <div className="flex-1 text-left sm:text-right">
                  <h2 className={`text-lg font-light transition-colors duration-200 ${
                    hoveredId === project._id ? 'text-black' : 'text-black/80'
                  }`}>
                    {project.title}
                    {project.subtitle && (
                      <span className={`ml-2 transition-colors duration-200 ${
                        hoveredId === project._id ? 'text-black/60' : 'text-black/40'
                      }`}>
                        {project.subtitle}
                      </span>
                    )}
                  </h2>
                </div>
              </div>

              {project.projectType === 'expandable' && project.widgets?.map((widget, index) => (
                widget.displayWhen === 'always' && (
                  <div key={index} className="mt-4">
                    {renderWidget(widget, false)}
                  </div>
                )
              ))}

              <AnimatePresence>
                {selectedProject?._id === project._id && project.projectType === 'expandable' && (
                  <motion.div
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: 'auto', opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                    transition={{ duration: 0.3 }}
                    className="overflow-hidden"
                  >
                    <div className="pt-4">
                      {project.widgets?.map((widget, index) => (
                        widget.displayWhen === 'expanded' && (
                          <div key={index} className="mt-4">
                            {renderWidget(widget, true)}
                          </div>
                        )
                      ))}
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </motion.div>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default ProjectGrid; 