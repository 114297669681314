import React from 'react';
import { FaSeedling } from 'react-icons/fa';

const MadeWith = () => {
  return (
    <div className="flex items-center gap-1">
      <span className="text-black/40">Made with</span>
      <FaSeedling className="text-green-600" />
      <span className="text-black/40">by</span>
      <a 
        href="https://lettuce.xyz" 
        target="_blank" 
        rel="noopener noreferrer"
        className="hover:opacity-50 transition-opacity"
      >
        Lettuce.xyz
      </a>
    </div>
  );
};

export default MadeWith; 