import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import ProjectGrid from './components/ProjectGrid';
import Contact from './components/Contact';
import { AuthProvider } from './context/AuthContext';

const App = () => {
  const location = useLocation();

  const getPageName = (pathname) => {
    switch (pathname) {
      case '/':
        return 'home';
      case '/contact':
        return 'contact';
      default:
        return '';
    }
  };

  const pageName = getPageName(location.pathname);

  return (
    <AuthProvider>
      <div className="App bg-white text-black min-h-screen flex flex-col font-helvetica">
        <Header pageName={pageName} />
        <main className="flex-grow w-full">
          <Routes>
            <Route path="/" element={<ProjectGrid />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </AuthProvider>
  );
};

export default App;
