import React, { useState, useEffect } from 'react';
import MadeWith from './MadeWith';

const Footer = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      
      // Show footer when within 20px of bottom
      const isNearBottom = scrollTop + windowHeight >= documentHeight - 20;
      setIsVisible(isNearBottom);
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Check initial position

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <footer 
      className={`fixed bottom-0 w-full bg-white text-black py-3 flex justify-center items-center font-helvetica text-xs border-t border-black/5 transition-opacity duration-200 ${
        isVisible ? 'opacity-100' : 'opacity-0 pointer-events-none'
      }`}
    >
      <MadeWith />
    </footer>
  );
};

export default Footer;