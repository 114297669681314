import React, { useState } from 'react';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
    inquiryType: '',
    budget: '',
    deadline: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add your form submission logic here (e.g., send data to an API)
    console.log('Form data submitted:', formData);
    // Clear form after submission
    setFormData({
      name: '',
      email: '',
      subject: '',
      message: '',
      inquiryType: '',
      budget: '',
      deadline: '',
    });
  };

  return (
    <div className="min-h-screen bg-white text-black px-4 py-8 flex flex-col">
      <h1 className="text-6xl md:text-6xl font-extrabold mb-8">Contact Us</h1>
      <form className="w-full max-w-lg" onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
            Name
          </label>
          <input
            className="w-full px-3 py-2 border border-black rounded-none"
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
            Email
          </label>
          <input
            className="w-full px-3 py-2 border border-black rounded-none"
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="subject">
            Subject
          </label>
          <input
            className="w-full px-3 py-2 border border-black rounded-none"
            type="text"
            id="subject"
            name="subject"
            value={formData.subject}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="inquiryType">
            Inquiry Type
          </label>
          <select
            className="w-full px-3 py-2 border border-black rounded-none"
            id="inquiryType"
            name="inquiryType"
            value={formData.inquiryType}
            onChange={handleChange}
            required
          >
            <option value="">Select an option</option>
            <option value="general">General Inquiry</option>
            <option value="project">Project Inquiry</option>
            <option value="support">Support</option>
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="budget">
            Budget
          </label>
          <input
            className="w-full px-3 py-2 border border-black rounded-none"
            type="text"
            id="budget"
            name="budget"
            value={formData.budget}
            onChange={handleChange}
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="deadline">
            Deadline
          </label>
          <input
            className="w-full px-3 py-2 border border-black rounded-none"
            type="date"
            id="deadline"
            name="deadline"
            value={formData.deadline}
            onChange={handleChange}
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="message">
            Message
          </label>
          <textarea
            className="w-full px-3 py-2 border border-black rounded-none"
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
          ></textarea>
        </div>
        <div className="mb-4">
          <button
            type="submit"
            className="w-full px-4 py-2 bg-black text-white font-bold rounded-none hover:bg-gray-800"
          >
            Send Message
          </button>
        </div>
      </form>
    </div>
  );
};

export default Contact;
